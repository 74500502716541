import React, { Component } from 'react';
import styles from './styles.module.scss';

class ContactForm extends Component {
  state = {
    showSuccess: false,
    name: '',
    email: '',
    message: '',
    nameErrorVisible: false,
    emailErrorVisible: false,
    messageErrorVisible: false,
  };

  onTextInputChange = (e, inputKey) => {
    this.setState({
      [inputKey]: e.target.value,
      [`${inputKey}ErrorVisible`]: false,
    });
  };

  submitForm = e => {
    e.preventDefault();

    const { name, email, message } = this.state;
    let valid = true;

    if (!name) {
      valid = false;
      this.setState({
        nameErrorVisible: true,
      });
    }

    if (
      !email ||
      !email.includes('@') ||
      !email.includes('.') ||
      email.lastIndexOf('.') < email.indexOf('@') ||
      email.indexOf('@') === 0 ||
      email.lastIndexOf('.') === email.length - 1 ||
      email.split('@').length > 2
    ) {
      valid = false;
      this.setState({
        emailErrorVisible: true,
      });
    }

    if (!message) {
      valid = false;
      this.setState({
        messageErrorVisible: true,
      });
    }

    if (valid) {
      this.setState({
        showSuccess: true,
      });
    }
  };

  render() {
    const {
      showSuccess,
      name,
      email,
      message,
      nameErrorVisible,
      emailErrorVisible,
      messageErrorVisible,
    } = this.state;

    return (
      <div className={styles.contactForm}>
        <h1 className={styles.heading}>Contact</h1>

        <div className={styles.card}>
          <h3 className={styles.formHeading}>
            Want to tell us how much you love us?
          </h3>

          <p className={styles.formIntro}>
            Or do you just want to tell us you love our Totoro tribute? Write it
            below!
          </p>

          {!showSuccess && (
            <form className={styles.form}>
              <fieldset className={styles.formField}>
                <label className={styles.formLabel} htmlFor="name">
                  Name
                </label>

                <input
                  className={styles.formInput}
                  name="name"
                  id="name"
                  type="text"
                  aria-required="true"
                  value={name}
                  onChange={e => this.onTextInputChange(e, 'name')}
                />

                {nameErrorVisible && (
                  <p className={styles.formInputError}>Name is invalid</p>
                )}
              </fieldset>

              <fieldset className={styles.formField}>
                <label className={styles.formLabel} htmlFor="email">
                  Email
                </label>

                <input
                  className={styles.formInput}
                  name="email"
                  id="email"
                  type="text"
                  aria-required="true"
                  value={email}
                  onChange={e => this.onTextInputChange(e, 'email')}
                />

                {emailErrorVisible && (
                  <p className={styles.formInputError}>Email is invalid</p>
                )}
              </fieldset>

              <fieldset className={styles.formField}>
                <label className={styles.formLabel} htmlFor="message">
                  Message
                </label>

                <textarea
                  className={styles.formTextarea}
                  name="message"
                  id="message"
                  rows="5"
                  aria-required="true"
                  value={message}
                  onChange={e => this.onTextInputChange(e, 'message')}
                />

                {messageErrorVisible && (
                  <p className={styles.formInputError}>Message is invalid</p>
                )}
              </fieldset>

              <button
                className={styles.formButton}
                type="submit"
                onClick={this.submitForm}
              >
                Send
              </button>
            </form>
          )}

          {showSuccess && (
            <p className={styles.formSuccess}>Thanks for the message!</p>
          )}

          <div className={styles.heart} />
        </div>
      </div>
    );
  }
}

export default ContactForm;
