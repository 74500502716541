import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import styles from './styles.module.scss';

function ContactNav() {
  return (
    <div className={styles.contactNav}>
      <Link className={styles.logo} to="/">
        Scribbledwall
      </Link>

      <ul className={styles.navList}>
        <li className={styles.navListItem}>
          <Link className={styles.navLink} to="/">
            Home
          </Link>
        </li>
        <li className={styles.navListItem}>
          <Link className={styles.navLink} to="/blog">
            News
          </Link>
        </li>
        <li className={styles.navListItem}>
          <a
            className={styles.navLink}
            href="http://www.redbubble.com/people/scribbledwall/shop"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shop
          </a>
        </li>
        <li className={styles.navListItem}>
          <Link className={styles.navLink} to="/about">
            About
          </Link>
        </li>
        <li className={styles.navListItem}>
          <Link
            className={classnames(styles.navLink, styles.navLinkActive)}
            to="/contact"
          >
            Contact*
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default ContactNav;
