import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

function Page({ children, bgColour }) {
  return (
    <div className={styles.page} style={{ backgroundColor: bgColour }}>
      {children}
    </div>
  );
}

Page.defaultProps = {
  bgColour: null,
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  bgColour: PropTypes.string,
};

export default Page;
