import React from 'react';

import Page from '../components/Page';
import SEO from '../components/seo';
import ContactNav from '../components/Contact/ContactNav';
import ContactForm from '../components/Contact/ContactForm';
import ContactFooter from '../components/Contact/ContactFooter';

function About() {
  return (
    <Page bgColour="#060100">
      <SEO keywords={[`gatsby`, `javascript`, `react`]} />

      <ContactNav />

      <ContactForm />

      <ContactFooter />
    </Page>
  );
}

export default About;
